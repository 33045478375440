<template>
  <div>
    <!-- 产品详情首图 -->
    <div class="ProductDetails">
      <div class="ProductDetails_img">
        <img src="@/assets/img/product/banner-jc.jpg" />
      </div>
      <div class="ProductDetails_content">
        <div>微林软件-缺陷视觉检测</div>
        <div>
          微林表面缺陷检测是采用先进的机器视觉检测技术，对工件表
          面的斑点、凹坑、划痕、色差、缺损等缺陷进行检测。 本系统可根
          据设定的技术指标要求自动进行检测，并对有缺陷部位进行标识，还
          可以根据需要自动分拣、剔除。
        </div>
      </div>
    </div>
    <!-- 产品详情内容 -->
    <div class="ProductDetails_box">
      <div>
        <div class="ProductDetails_boxItem">
          <div>
            <div class="decoration_title">产品外表破损检测</div>
            <div class="decoration_text">
              <div class="decoration"></div>
              <div>
                金属、塑料、玻璃产品外表破损检测，标定破损点，精准定位破损点。
              </div>
            </div>
            <div class="decoration_content">
              <div class="decoration"></div>
              <div>
                准确、快速地探测零件表面缺陷，直接关系产品质量，若不及时剔除不合格产品，将会带来质量隐患。
                识别和检测重要零件关键部位的表面缺陷迄今还是以人工目测为主。
                由于考虑到工艺执行过程中的复杂性,必须提出规范的评定标准。
                如，对连杆大小头结合面可能存在的破损，就有如下具体规定：
                破口面积小于2.0mm2；破口任何一方向的线性长度小于2.0mm。只要符合其中一个条件，就将判定为不合格而被剔除。
                根据零件的特点，破口可能出现的区域在结合面(线)的外侧，其范围呈“八”字形。在此情况下，依靠人工目测、估算的方式，
                不但效率低，劳动强度大，且无法准确执行上述标准中的规定。
                另一方面，即使采用其它常规测量方法，也难以达到上述目的。
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/img/product/QueXian01.png" />
          </div>
        </div>
        <div class="ProductDetails_boxItem">
          <div>
            <img src="@/assets/img/product/QueXian02.jpg" />
          </div>
          <div>
            <div class="decoration_title">产品形状变形检测</div>
            <div class="decoration_text">
              <div class="decoration"></div>
              <div>检测产品成品形状是否符合生产计划标准。</div>
            </div>
            <div class="decoration_text">
              <div class="decoration"></div>
              <div>精准到0.01MM</div>
            </div>
            <div class="decoration_content">
              <div class="decoration"></div>
              <div>
                图像处理技术用于表面缺陷检测的原理
                图像处理技术又称“机器视觉”，乃是将被测对象的图像作为信息的载体，从中提取有用的信息来达到测量的目的。它具有非接触、高速度、测量范围大、获得的信息丰富等优点。通过CCD(Charge
                Coupled
                Device)摄像头与光学系统、处理系统的组合，可实现不同的检测要求。
              </div>
            </div>
          </div>
        </div>
        <div class="ProductDetails_boxItem">
          <div>
            <div class="decoration_title">零配件外观等级分类检测</div>
            <div class="decoration_text">
              <div class="decoration"></div>
              <div>标定零配件外观质量等级</div>
            </div>
            <div class="decoration_text">
              <div class="decoration"></div>
              <div>外观打分，10分产品为最优。0分产品为淘汰品。</div>
            </div>
            <div class="decoration_content">
              <div class="decoration"></div>
              <div>
                外观检测系统主要用
                于快速识别样品的外观缺陷，如凹坑、裂纹、翘曲、缝隙、污渍、沙粒、毛刺、气泡、颜色不均匀等，被检测样品可以是透明体也可以是不透明体
              </div>
            </div>
          </div>
          <div>
            <img src="@/assets/img/product/QueXian03.jpg" />
          </div>
        </div>
        <div class="ProductDetails_boxItem">
          <div>
            <img src="@/assets/img/product/QueXian04.png" />
          </div>
          <div>
            <div class="decoration_title">产品形状变形检测</div>
            <div class="decoration_text">
              <div class="decoration"></div>
              <div>
                产品本身的毛刺在包装、运输过程中会对其他产品或本产品的外包装进行二
                次损伤。
              </div>
            </div>
            <div class="decoration_content">
              <div class="decoration"></div>
              <div>
                以往的产品外观检测一般是用肉眼识别的方式，因此有可能人为因素导致衡量标准不统一，以及长时间检测由于视觉疲劳会出现误判的情况。随着计算机技术以及光、机、电等技术的深度配合，机器视觉检测具备了快速、准确的检测特点。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scope>
/* 详情页首图 */
.ProductDetails {
  width: 100%;
  height: 600px;
  position: relative;
}
.ProductDetails_img img {
  width: 100%;
  height: 600px;
  display: flex;
  object-fit: cover;
}
.ProductDetails_content {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.ProductDetails_content > div:nth-of-type(1) {
  font-size: 44px;
  text-align: center;
}
.ProductDetails_content > div:nth-of-type(2) {
  margin-top: 60px;
  font-size: 28px;
  font-weight: 100;
  text-indent: 2em;
  line-height: 60px;
}
/* 详情页内容 */
/* 详情页内容-模块 */
.ProductDetails_box {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ProductDetails_box > div {
  width: 1200px;
}
.ProductDetails_boxItem {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
}
.ProductDetails_boxItem img {
  width: 480px;
  height: 320px;
  display: flex;
  object-fit: cover;
  border-radius: 10px;
}

.ProductDetails_boxItem > div > div {
  width: 620px;
  display: flex;
  justify-content: flex-start;
}
.decoration_title {
  color: #333333;
  font-size: 28px;
  font-weight: 600;
}
.decoration {
  width: 8px;
  height: 20px;
  background: #dba989;
  margin-right: 10px;
}
.decoration_text {
  width: 80%;
  font-size: 18px;
  margin: 40px 0 35px;
  display: flex;
  justify-content: flex-start;
}
.decoration_content {
  display: flex;
  justify-content: flex-start;
}
.decoration_content > div:nth-of-type(2) {
  font-size: 18px;
  width: 80%;
  line-height: 36px;
}
</style>